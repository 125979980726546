import { styled, Theme } from '@mui/material/styles';

/*
 * Constants.
 */

interface StyledLogoImgProps {
  src: string;
  alt: string;
  theme?: Theme;
}

export const StyledLogoImg = styled('img')<StyledLogoImgProps>(({ theme }) => ({
  display: 'inline',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    height: '100px',
    width: '100px',
  },
  [theme.breakpoints.up('md')]: {
    height: '100%',
    width: 'auto',
  },
}));
