import { gql } from '@apollo/client';

const expressBookContractItemFragment = gql`
  fragment ExpressBookContractItemFragment on ContractItem {
    id
    name
    price
    basePrice
    details
    quantity
    unit
    total
    image(width: 600, height: 375)
    choices {
      id
      name
      limit
      options {
        id
        name
        price
      }
    }
    addons {
      id
      name
      price
    }
    optionIds
    addonIds
    displayOnGuestMenu
  }
`;

const expressBookContractFragment = gql`
  fragment ExpressBookContractFragment on Contract {
    id
    venuePolicy {
      id
      name
      body
    }
    minimumAmount
    minimumEnabled
    depositRate
    depositAmount
    totals
    contractItems {
      ...ExpressBookContractItemFragment
    }
    contractTaxes {
      id
      name
      enabled
      applyToMinimum
      rate
    }
    contractFees {
      id
      name
      enabled
      applyToMinimum
      rate
    }
  }
  ${expressBookContractItemFragment}
`;

export const CREATE_BOOKING_MUTATION = gql`
  mutation CreateExpressBookEventMutation($input: CreateEventInput!) {
    createEvent(input: $input) {
      event {
        slug
      }
      errors {
        message
      }
    }
  }
`;

export const VENUE_BOOKINGS_QUERY = gql`
  query VenueBookingsQuery($venueSlug: String!) {
    marketplaceVenue(slug: $venueSlug) {
      id
      slug
      name
      minGroupSize
      defaultPaymentProvider
      stripeAccount {
        id
        stripeAccountId
      }
      timezone
      currency
      address {
        id
        streetAddress1
        streetAddress2
        city
        state
        zip
        country
      }
      images(limit: 1) {
        id
        url
      }
    }
  }
`;

export const SPACE_BOOKINGS_QUERY = gql`
  query SpaceBookingsQuery($spaceSlug: String!) {
    marketplaceSpace(slug: $spaceSlug) {
      id
      slug
      name
      seated
      standing
      images(limit: 1) {
        id
        url
      }
    }
  }
`;

export const VENUE_MENU_QUERY = gql`
  query VenueExpressBookMenuQuery($venueSlug: String!) {
    marketplaceVenue(slug: $venueSlug) {
      id
      timezone
      name
      address {
        id
        streetAddress1
        streetAddress2
        city
        state
        zip
        country
      }
      taxes {
        id
        name
        rate
      }
      fees {
        id
        name
        rate
      }
      menu {
        id
        pdf
        menuItems(displayOnExpressBook: true) {
          id
          name
          image(width: 600, height: 375)
          details
          price
          unit
          displayOnGuestMenu
          displayOnExpressBook
          menuCategory {
            id
            name
            position
          }
          details
          addons {
            id
            name
            price
          }
          choices {
            id
            name
            limit
            options {
              id
              name
              price
            }
          }
          taxes {
            id
            name
            rate
          }
          fees {
            id
            name
            rate
          }
        }
        menuCategories {
          id
          name
          position
        }
      }
    }
  }
`;

export const CONTRACT_QUERY = gql`
  query EventContractQuery($eventSlug: String!) {
    marketplaceEvent(slug: $eventSlug) {
      id
      duration
      startOffset
      endOffset
      startTime
      endTime
      startDate
      planner {
        id
        firstName
        lastName
        email
        phone
      }
      groupSize
      expressBookRequest {
        id
        status
      }
      contract {
        id
        ...ExpressBookContractFragment
      }
    }
  }
  ${expressBookContractFragment}
`;

export const CREATE_CONTRACT_ITEM_MUTATION = gql`
  mutation CreateExpressBookContractItemMutation(
    $input: CreateContractItemInput!
  ) {
    createContractItem(input: $input) {
      contract {
        id
        ...ExpressBookContractFragment
      }
      contractItem {
        id
        ...ExpressBookContractItemFragment
      }
    }
  }
  ${expressBookContractFragment}
  ${expressBookContractItemFragment}
`;

export const UPDATE_CONTRACT_ITEM_MUTATION = gql`
  mutation UpdateExpressBookContractItemMutation(
    $input: UpdateExpressBookItemInput!
  ) {
    updateExpressBookItem(input: $input) {
      contract {
        id
        ...ExpressBookContractFragment
      }
    }
  }
  ${expressBookContractFragment}
`;

export const REMOVE_CONTRACT_ITEM_MUTATION = gql`
  mutation RemoveExpressBookContractItemMutation(
    $input: RemoveContractItemInput!
  ) {
    removeContractItem(input: $input) {
      contract {
        id
        ...ExpressBookContractFragment
      }
    }
  }
  ${expressBookContractFragment}
`;

export const VENUE_NAV_BAR_QUERY = gql`
  query VenueNavBarQuery($venueSlug: String!) {
    marketplaceVenue(slug: $venueSlug) {
      id
      name
      address {
        id
        city
        state
      }
    }
  }
`;

export const CREATE_SETUP_INTENT_MUTATION = gql`
  mutation CreateSetupIntentMutation($input: CreateSetupIntentInput!) {
    createSetupIntent(input: $input) {
      stripeClientSecret
    }
  }
`;

export const SEND_EXPRESS_BOOK_REQUEST_MUTATION = gql`
  mutation SendExpressBookRequestMutation(
    $input: SendExpressBookRequestInput!
  ) {
    sendExpressBookRequest(input: $input) {
      expressBookRequest {
        id
        status
      }
    }
  }
`;
